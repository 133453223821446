<template>
  <!-- 公共部分使用插槽，封装组件component,waike组件 -->
  <!-- <waike>
    <template #form>里面为会改变的内容 </template>
  </waike> -->
  <waike title="登录">
    <!-- <template #form> 低版本不支持 -->
    <template slot="form">
      <router-link to="/register">还没有账号，点击注册</router-link>
      <div class="login-from">
        <el-form
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          label-width="80px"
          class="demo-ruleForm"
        >
          <el-form-item label="用户名" prop="username">
            <el-input
              type="text"
              auto-complete="off"
              placeholder="请输入用户名"
              v-model="ruleForm.username"
            ></el-input>
          </el-form-item>
          <el-form-item label="用户密码" prop="password">
            <el-input
              type="password"
              auto-complete="off"
              placeholder="请输入密码"
              v-model="ruleForm.password"
            ></el-input>
          </el-form-item>

          <el-form-item label="用户类型" prop="value">
            <el-select
              placeholder="请选择用户类型"
              v-model="ruleForm.value"
              style="width: 100%"
            >
              <el-option label="请选择用户类型" value=""></el-option>
              <el-option
                v-for="item in ruleForm.identity"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="验证码" prop="verify">
            <el-input
              type="text"
              auto-complete="off"
              placeholder="请输入验证码"
              v-model="ruleForm.verify"
              ><el-button
                slot="append"
                v-html="ruleForm.verifysvg"
                @click="getVerify"
              ></el-button
            ></el-input>
          </el-form-item>

          <div class="button">
            <el-button type="primary" @click="submitForm('ruleForm')"
              >登录</el-button
            >
            <el-button type="primary" @click="resetForm('ruleForm')"
              >重置</el-button
            >
          </div>

          <router-link to="/forgetPassword">忘记密码</router-link>
        </el-form>
      </div>
    </template>
  </waike>
</template>

<script>
import waike from "./slot/waike.vue";
export default {
  components: { waike },
  data() {
    var validateName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入用户名"));
      } else {
        callback();
      }
    };

    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };

    var validateType = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择用户类型"));
      } else {
        callback();
      }
    };

    var validateYzm = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else {
        if (
          this.ruleForm.verify.toLowerCase() !==
          this.ruleForm.verifyText.toLowerCase()
        ) {
          callback(new Error("验证码不正确"));
          this.getVerify();
        } else {
          callback();
        }
      }
    };
    return {
      ruleForm: {
        identity: ["超级管理员", "教师", "学生"],
        value: "",
        username: "",
        password: "",
        verify: "",
        verifysvg: "",
        verifyText: "",
      },
      rules: {
        username: [{ validator: validateName, trigger: "blur" }],
        password: [{ validator: validatePass, trigger: "blur" }],
        value: [{ validator: validateType, trigger: "change" }],
        verify: [{ validator: validateYzm, trigger: "blur" }],
      },
    };
  },
  methods: {
    //登录
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var loginUrl, user;
          if (this.ruleForm.value == "超级管理员") {
            loginUrl = "/api/super/login";
            user = "super";
          } else if (this.ruleForm.value == "教师") {
            loginUrl = "/api/teacher/login";
            user = "teacher";
          } else if (this.ruleForm.value == "学生") {
            loginUrl = "/api/student/login";
            user = "student";
          }
          this.$http
            .post(loginUrl, {
              username: this.ruleForm.username,
              password: this.ruleForm.password,
              verify: this.ruleForm.verify,
            })
            .then(
              (res) => {
                if (res.data.status == 1) {
                  localStorage.setItem("token", res.data.token);
                  this.$message({ message: "登录成功", type: "success" });
                  this.$router.push("/manage/" + user + "/center");
                } else {
                  this.$message({ message: res.data.msg, type: "error" });
                }
              },
              (err) => {
                this.$message({ message: "账号或密码错误", type: "error" });
              }
            );
          //更新验证码
          this.getVerify();
        } else {
          return false;
        }
      });
    },
    //重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    //切换验证码
    getVerify() {
      this.$http.get("/api/global/verifysvg").then(
        (res) => {
          this.ruleForm.verifysvg = res.data.data;
          this.ruleForm.verifyText = res.data.text;
        },
        (err) => {
          console.log(err);
        }
      );
    },
  },
  mounted() {
    //挂载阶段加载验证码
    this.getVerify();
  },
  //通过路由规则，离开该组件时被调用
  beforeRouteLeave(to, from, next) {
    //加载配置
    this.$http.get("/api/super/allgetconfig").then((res) => {
      this.$store.commit("setConfig", res.data.data);
      localStorage.setItem('Config',JSON.stringify(res.data.data))
    });
    //加载用户信息
     this.$http.post('/api/global/loginStatus',{token:localStorage.getItem('token')}).then((res)=>{
      if(res.data.status==1){  //token合法
        this.$store.commit('setUserdata', res.data.data)
        localStorage.setItem('Userdata',JSON.stringify(res.data.data))
          // console.log('LoginbeforeRouteLeave',res.data.data);
      }
    })

    next()
  },
};
</script>

<style lang="scss">
</style>
