<template>
  <div class="waike">
    <div class="login-content-wrap">
      <div class="login-content">
        <h2>
          在线考试系统:<span class="span">{{ title }}</span>
        </h2>
        <slot name="form"></slot>
      </div>
    </div>
   <div class="beian">
     <div class="content">
      <img src="../../assets/gongan.png" alt="">
      <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index" >赣ICP备2023007871号</a>
      <a style="margin-left: 20px;" target="_blank" href="https://www.beian.gov.cn/portal/recordQuery" >粤公网安备44132302100227号</a>
     </div>
   </div>
  </div>
</template>
<script>
export default {
  props: ["title"],
};
</script>
<style lang="scss">
/* 登录界面 */
.waike {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: url("../../assets/images/login-bg-small.jpg");
  background-size: cover;
  min-height: 580px;
  text-align: center;
}
.beian{
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.4);
  .content{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    a{
      color: white;
    }
  }
}
.span {
  color: #007bff;
}
.login-content-wrap {
  position: absolute;
  width: 500px;
  height: 100%;
  min-height: 580px;
  background: rgba(255, 255, 255, 0.4);
  top: 0;
  right: 0;

  .login-content {
    width: 400px;
    height: 500px;
    margin: auto;
    background: rgba(255, 255, 255, 1);
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    font-size: 12px;
    transform: translateY(-50%);
  }

  .login-from {
    width: 80%;
    margin: 0 auto;
    margin-top: 50px;
  }
  .button {
    display: flex;
    justify-content: space-between;
  }

  a {
    text-decoration: none;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    color: #007bff;
  }
}
</style>